import React from 'react';
import { css } from 'emotion';

import Logo from '../../assets/images/logo-aigeeks-footer.svg';

const Footer = ({ data }) => {
  const pages = data.page.reverse();
  const { meeting, sponsors, findUsLinks } = data;

  const style = css`
    .find-us {
      background-color: var(--grey);
      padding: var(--spacing-lg) 0;
    }

    .sponsors {
      .col {
        img {
          max-width: 100%;
          max-height: 100px;
        }
      }
    }

    .logo-container {
      max-width: 900px;
      margin: 0 auto;
    }

    .main {
      background-color: var(--dark);

      p:not('.small') {
        max-width: 400px;
        color: var(--grey-dark);
      }

      ul {
        li {
          a {
            color: var(--grey-dark);
          }
        }
      }
    }

    .legal {
      border-top: 1px solid #32354c;
    }

    p,
    li a {
      font-size: 14px;
    }
  `;

  return (
    <div className={style}>
      <div className="sponsors">
        <div className="container">
          <div className="spacing-md-inset">
            <div className="row is-centered spacing-xs-stack">
              <h3 className="dark black">Our Sponsors</h3>
            </div>
            <div className="row is-centered">
              {sponsors
                .sort((a, b) => a.order - b.order)
                .map((sponsor, index) => (
                  <div key={`sponsor-${index}`} className="col">
                    <div className="spacing-md-inset">
                      <a href={sponsor.exitUrl} target="_blank">
                        <img src={sponsor.logo.fields.file.url} alt="" />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="find-us">
        <div className="container">
          <div className="row is-centered spacing-md-stack">
            <h3 className="color-blue-light black">Find us on</h3>
          </div>
          <div className="row is-centered logo-container">
            {findUsLinks
              .sort((a, b) => a.order - b.order)
              .map((findUsLink, index) => (
                <div key={`findUsLink-${index}`} className="col">
                  <div className="spacing-md-inset">
                    <a href={findUsLink.exitUrl} target="_blank">
                      <img src={findUsLink.logo.fields.file.url} alt="" />
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="main">
        <div className="container spacing-lg-inset-expanded">
          <div className="row is-spaced spacing-lg-stack">
            <div className="col-3">
              <div className="spacing-sm-inset">
                <img className="spacing-md-stack" src={Logo} alt="" />
                <p className="spacing-md-stack">
                  SoftGeeks is a Canadian Not-for-Profit that aims to empower developer to write better software.
                </p>
                <a
                  href="https://aigeeks.us20.list-manage.com/subscribe?u=bfef20cca074487e1517eaed0&id=65b9f76847"
                  className="button cta"
                >
                  Subscribe to newsletter
                </a>
              </div>
            </div>
            <div className="col-1">
              <div className="spacing-sm-inset">
                <h6 className="black color-white spacing-sm-stack">Pages</h6>
                <ul>
                  {pages.map((page, index) => (
                    <li key={`footer-page-${index}`}>
                      <a href={`/${page.pageSlug}`}>{page.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-2">
              <div className="spacing-sm-inset">
                <h6 className="black color-white spacing-sm-stack">Coming Events</h6>
                <ul>
                  {meeting.map((meet, index) => (
                    <li key={`meet-page-${index}`}>
                      <a href={meet.exitUrl} target="_blank">
                        {meet.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="legal row spacing-md-stack">&nbsp;</div>
          <div className="row">
            <div className="col-1">
              <div className="spacing-md-inset is-centered">
                <p className="color-grey-dark small">
                  All Content © AIGeeks 2015-2020. Built by{' '}
                  <a className="color-code-purple" href="https://codeshinobi.com">
                    Code Shinobi
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
