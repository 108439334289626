import React, { useContext } from 'react';
import { ContentfulContext } from '../context/ContentfulContext';
import upperFirst from 'lodash/upperFirst';
import { Helmet } from 'react-helmet';

import * as Components from '../components';
import { cx } from 'emotion';

const Builder = (props) => {
  const contentfulData = useContext(ContentfulContext);
  const thisPage = {};
  const pages = [];
  const propsPageId = props.match.params.pageId || 'home';

  contentfulData['page'].map((item) => {
    pages.push(item);

    if (item.pageSlug === propsPageId) {
      thisPage['page'] = item;
    }
  });

  pages.sort((a, b) => b.order - a.order);

  Object.keys(contentfulData)
    .filter((key) => key !== 'page')
    .map(function (key) {
      contentfulData[key].map((item) => {
        if (key === 'hero') {
          if (item.pageSlug === propsPageId) {
            thisPage[key] = item;
          }
        } else {
          if (thisPage.page.contentName === key) {
            thisPage['content'] = contentfulData[key];

            thisPage['content'].forEach((item) => {
              item.contentType = key;
            });
          }
        }
      });

      return contentfulData[key].map((item) => item.pageSlug === props.pageId && item);
    });

  const Header = Components['Header'];
  const Hero = Components['Hero'];
  const Footer = Components['Footer'];

  if (thisPage.content) {
    thisPage.content.sort((a, b) => a.order - b.order);
  }

  return (
    <div>
      {thisPage.content ? (
        <div>
          {thisPage.page.pageTitle && (
            <Helmet>
              <title>{thisPage.page.pageTitle}</title>
            </Helmet>
          )}
          <Header data={pages} selected={thisPage.page.name} />
          <Hero parentData={contentfulData} data={thisPage.hero} />
          <div className="container spacing-md-inset">
            <div
              className={cx('row', {
                ['is-centered']: thisPage.page.contentAlignment === 'center',
              })}
            >
              {thisPage.content.map((item, index) => {
                const ThisComponent = Components[upperFirst(item.contentType)];
                return <ThisComponent key={`${item.contentType}-${index}`} data={item} />;
              })}
              {thisPage.page.name === 'Videos' && (
                <a
                  href="https://youtube.com/channel/UCN0awZ4x6l21FaclBa0UUeQ"
                  className="link color-red col-4 is-centered bold"
                  style={{ width: '100%' }}
		  target="_blank"
                >
                  View all videos
                </a>
              )}
            </div>
          </div>
          <Footer data={contentfulData} />
        </div>
      ) : (
        <div className="404">
          <h1>404 not found</h1>
        </div>
      )}
    </div>
  );
};

export default Builder;
