import React from 'react';
import { css, cx } from 'emotion';

const Meeting = ({ data }) => {
  const imageUrl = data.image ? data.image.fields.file.url : '';

  const style = css`
    .image {
      background-size: cover;
      padding-top: 56.25%;
      background-image: url(${imageUrl});
      background-position: center center;
    }

    a {
      color: var(--red);
    }
  `;

  return (
    <div className={cx('col', style)}>
      <div className="spacing-sm-inset">
        <a href={data.exitUrl} target="_blank">
          <div className="image spacing-sm-stack"></div>
        </a>
        <h5 className="bold spacing-sm-stack">{data.title}</h5>
        <p className="spacing-md-stack">{data.body}
	<br/>
        <a href={data.exitUrl} target="_blank">
          Learn More
        </a>
	</p>
      </div>
    </div>
  );
};

export default Meeting;
