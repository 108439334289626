import React from 'react';
import { css, cx } from 'emotion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Form from '../form/Form';

const ContactForm = ({ data }) => {
  const style = css``;

  return (
    <div className={style}>
      <div className="content">
        <div className="row">
          <div className="col-1">
            <Form />
          </div>
          <div className="col-1">
            <h6 className="bold spacing-sm-stack">{data.title}</h6>
            {documentToReactComponents(data.body)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
