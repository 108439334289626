import React, { useRef } from 'react';
import { css, cx } from 'emotion';

const Input = ({ name, type, label, placeholder, value }) => {
  const parent = useRef();

  const style = css`
    position: relative;
    display: block;
    width: 100%;
    background-color: #f9f9f9;
    border: 1px solid var(--grey);
    border-radius: 10px;

    color: var(--dark);

    label,
    .placeholder {
      font-size: var(--type-p);
      font-weight: bold;
    }

    label {
      position: absolute;
      top: 50%;
      left: var(--spacing-sm);
      transform: translateY(-50%);
      transition: opacity 0.2s, transform 0.2s;
      color: var(--dark);
    }

    .placeholder {
      color: var(--red);
      position: absolute;
      top: calc(var(--spacing-ty) + calc(var(--spacing-ty) / 2));
      left: var(--spacing-sm);
      opacity: 0;
      transform: scale(0);
      transition: opacity 0.2s, transform 0.2s;
    }

    input,
    textarea {
      padding: var(--spacing-md) var(--spacing-sm) var(--spacing-sm);
      font-size: var(--type-p);
      width: calc(100% - calc(var(--spacing-sm) * 2));
    }

    &.type-textarea {
      label {
        top: var(--spacing-md);
      }
    }

    &.hasValue {
      background-color: #fff;
      border: 1px solid var(--dark);

      .placeholder {
        opacity: 1;
        transform: scale(1);
      }

      label {
        opacity: 0;
        transform: scale(0);
      }
    }
  `;

  const checkInput = (e, type) => {
    if (!parent.current.classList.contains('hasValue') && type === 'focus') {
      parent.current.classList.add('hasValue');
    }

    if (e.currentTarget.value.length > 0) {
      parent.current.classList.add('hasValue');
    } else if (type !== 'focus') {
      parent.current.classList.remove('hasValue');
    }
  };

  const elements = {
    input: (
      <input
        type="text"
        name={name}
        id={name}
        value={value}
        onFocus={(e) => {
          checkInput(e, 'focus');
        }}
        onBlur={(e) => {
          checkInput(e);
        }}
      />
    ),
    textarea: (
      <textarea
        name={name}
        id={name}
        cols="30"
        rows="10"
        onFocus={(e) => {
          checkInput(e, 'focus');
        }}
        onBlur={(e) => {
          checkInput(e);
        }}
      >
        {value}
      </textarea>
    ),
  };

  return (
    <div ref={parent} className={cx(style, 'spacing-sm-stack', `type-${type}`)}>
      <div className="placeholder">{label}</div>
      {placeholder && <label htmlFor={name}>{label}</label>}
      {elements[type]}
    </div>
  );
};

Input.defaultProps = {
  type: 'input',
};

export default Input;
