import React from 'react';
import { ContentfulContextProvider } from './context/ContentfulContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './assets/styles/builder.scss';

import Builder from './pages/Builder';

function App() {
  return (
    <ContentfulContextProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Builder} />
          <Route path="/:pageId" component={Builder} />
        </Switch>
      </Router>
    </ContentfulContextProvider>
  );
}

export default App;
