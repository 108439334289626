import React from 'react';
import { css, cx } from 'emotion';

const Hero = ({ parentData, data }) => {
  const heroUrl = data.image ? data.image.fields.file.url : '';

  const style = css`
    display: flex;
    background-image: url(${heroUrl});
    background-size: cover;
    background-position: center center;
    align-content: center;
    justify-content: center;
    text-align: center;

    .content {
      max-width: 490px;
      min-height: 300px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      h1,
      h6 {
        color: white;
      }

      h1 {
        word-break: keep-all;
      }
    }
  `;

  const banner = css`
    background-color: var(--yellow);
    border: 1px solid var(--yellow-dark);
    text-align: center;

    p {
      color: var(--dark);
    }
  `;

  return (
    <div className="container">
      <div className={style}>
        <div className="content spacing-md-inset">
          <h1 className="black spacing-sm-stack">{data.title}</h1>
          {data.pageSlug === 'home' ? (
            <div>
              <h6 className="spacing-md-stack">{data.subTitle}</h6>
              <a
                href="https://us20.campaign-archive.com/home/?u=bfef20cca074487e1517eaed0&id=65b9f76847"
                className="button cta"
		target="_blank"
              >
                Subscribe to newsletter
              </a>
            </div>
          ) : (
            <h6>{data.subTitle}</h6>
          )}
        </div>
      </div>
      {data.pageSlug === 'home' && (
        <div className={cx(banner, 'spacing-xs-inset')}>
          <p className="bold">{parentData.statisticsLine[0].body}</p>
        </div>
      )}
    </div>
  );
};

export default Hero;
