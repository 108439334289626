import React from 'react';
import { css, cx } from 'emotion';

const Dropdown = ({ name, type, label, placeholder, value, options }) => {
  const style = css`
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
    border: 1px solid var(--dark);
    border-radius: 10px;

    color: var(--dark);

    label {
      font-size: var(--type-p);
      font-weight: bold;
    }

    label {
      color: var(--red);
      position: absolute;
      top: calc(var(--spacing-ty) + calc(var(--spacing-ty) / 2));
      left: var(--spacing-sm);
      opacity: 1;
      transform: scale(1);
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      padding: var(--spacing-md) var(--spacing-sm) var(--spacing-sm);
      font-size: var(--type-p);
      width: calc(100%);
    }

    &::after {
      content: '';

      position: absolute;
      right: var(--spacing-sm);
      top: calc(50% - 5px);

      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;

      border-top: 10px solid var(--dark);
    }
  `;

  return (
    <div className={cx(style, 'spacing-sm-stack', `type-${type}`)}>
      <label htmlFor={name}>{label}</label>
      <select name={name} id={name}>
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
