import React, {
	useState, useLayoutEffect
} from 'react';
import { createClient } from "contentful";


const ContentfulContext = React.createContext();

const ContentfulContextProvider = ({ children }) => {

	const [siteData, setSiteData] = useState(null);

	const fetchDataFromApi = () => {
		const client = createClient({
			space: 'srw0j6fy7bpc',
			environment: 'master', // defaults to 'master' if not set
			accessToken: '4gcsP48lfujryXxpxKID-_kGQhweMsN_HkgZXNmuBSs'
		})

		client.getEntries()
			.then((response) => {
				let contentObj = {};

				response.items.map(item => {
					const contentType = item.sys.contentType.sys.id;

					if (contentObj[`${contentType}`] === undefined) {
						contentObj[`${contentType}`] = [item.fields];
					} else {
						contentObj[`${contentType}`].push(item.fields);
					}
				})

				setSiteData(contentObj)
			})
	};
	
	useLayoutEffect(() => {

		if(!siteData) {
			fetchDataFromApi();
		}
	})

	return (
		<ContentfulContext.Provider value={siteData}>
			{siteData && children}
		</ContentfulContext.Provider>
	)
}

export {
	ContentfulContext,
	ContentfulContextProvider
};