import React from 'react';
import { css, cx } from 'emotion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const TextColumn = ({ data }) => {
  const style = css`
    i {
      font-style: italic;
    }

    b,
    b > i {
      font-weight: 600;
      color: var(--dark);
    }

    blockquote {
      padding-top: var(--spacing-md);
      position: relative;

      &:before {
        content: '“';
        position: absolute;
        top: var(--spacing-md);
        left: -18px;
        font-size: var(--type-h1);
        font-family: Arial;
        color: var(--blue-dark);
        opacity: 0.5;
      }
    }

    .title {
      padding-bottom: var(--spacing-md);

      + blockquote {
        padding-top: 0;

        &:before {
          top: 0;
        }
      }
    }
  `;

  return (
    <div className={cx('col-1', style)}>
      <div className="spacing-md-inset">
        <h5 className="bold title">{data.title}</h5>
        {documentToReactComponents(data.body)}
      </div>
    </div>
  );
};

export default TextColumn;
