import React from 'react';
import { css } from 'emotion';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Video = ({ data }) => {
  const style = css`
    .image {
      width: 100%;
      max-width: 240px;

      img {
        width: 100%;
      }
    }
  `;

  return (
    <a href={data.exitUrl} className={`spacing-lg-stack ${style}`} target="_blank">
      <div className="row">
        <div className="image spacing-md-inline spacing-md-stack">
          <img src={data.image.fields.file.url} alt="" />
        </div>
        <div className="col-1">
          <h5 className="bold spacing-xs-stack">{data.title}</h5>
          <div className="spacing-xs-stack">{documentToReactComponents(data.description)}</div>
          <p className="color-red bold">Watch Video</p>
        </div>
      </div>
    </a>
  );
};

export default Video;
