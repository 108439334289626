import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Input from './elements/Input';
import DropDown from './elements/Dropdown';

const Form = () => {
  const [formState, setFormState] = useState(0);

  const submitForm = (e) => {
    e.preventDefault();

    const sendData = {
      message: '',
    };

    const inputs = $(e.currentTarget).serializeArray();

    inputs.forEach((input) => {
      sendData.message += `${input.name}: ${input.value} \n`;
    });

    $.post('/email/send', sendData, function (data) {
      setFormState(1);
    });
  };

  return (
    <div className="spacing-md-inline">
      {!formState ? (
        <form onSubmit={(e) => submitForm(e)}>
          <Input name="name" label="Your name" placeholder="Your name" />

          <Input name="email" label="Your email" placeholder="Your email" />
          
          <Input name="message" label="Your message" type="textarea" placeholder="Your message" />

          <Input name="website" label="Your website" placeholder="Website" />

          <input className="button cta" type="submit" value="Send" />
        </form>
      ) : (
        <div>
          <h6 className="blue-dark">Thank you for contacting us, we'll be in touch.</h6>
        </div>
      )}
    </div>
  );
};

export default Form;
