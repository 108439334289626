import React from 'react';

const NavMenu = () => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <g>
      <path
        className="top"
        d="M68.3,32.5H31.7c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2h36.6c1.8,0,3.2-1.4,3.2-3.2S70.1,32.5,68.3,32.5z"
      ></path>
      <path
        className="mid"
        d="M68.3,45.8H31.7c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2h36.6c1.8,0,3.2-1.4,3.2-3.2S70.1,45.8,68.3,45.8z"
      ></path>
      <path
        className="bot"
        d="M68.3,59.1H31.7c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2h36.6c1.8,0,3.2-1.4,3.2-3.2   C71.5,60.6,70.1,59.1,68.3,59.1z"
      ></path>
      <path d="M50,5C25.2,5,5,24.7,5,49c0,24.3,20.2,44,45,44s45-19.8,45-44C95,24.7,74.8,5,50,5z M50,87   c-21.5,0-38.9-17-38.9-38c0-20.9,17.5-38,38.9-38c21.5,0,38.9,17,38.9,38C88.9,69.9,71.5,87,50,87z"></path>
    </g>
  </svg>
);

export default NavMenu;
