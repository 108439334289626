import React from 'react';
import { cx, css } from 'emotion';

const TeamMember = ({ data }) => {
  console.log(data);
  const imageUrl = data.profileImage ? data.profileImage.fields.file.url : '';

  const style = css`
    position: relative;
    width: calc(20% - var(--spacing-sm) * 2);
    min-width: 215px;
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(30, 30, 30, 0.12);
    text-align: center;
    padding: 80px var(--spacing-sm) var(--spacing-md);
    margin: var(--spacing-lg) var(--spacing-sm) var(--spacing-md);

    .image {
      position: absolute;
      top: -40px;
      left: 50%;

      transform: translateX(-50%);
      border-radius: 50%;
      width: 105px;
      height: 105px;

      background-image: url(${imageUrl});
      background-position: center center;
      background-size: cover;
      background-color: var(--grey);
    }

    p {
      font-size: 14px;
      line-height: 1.25;
    }
  `;

  return (
    <div className={style}>
	<a href={data.exitUrl} target="_blank">
      <div className="image"></div>
      <h6 className="bold">{data.name}</h6>
      <p className="normal">{data.jobTitle}</p>
	</a>
    </div>
  );
};

export default TeamMember;
