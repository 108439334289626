import React, { useState } from 'react';
import { css, cx } from 'emotion';

import Logo from '../../assets/logo-header.svg';
import NavMenu from '../../assets/NavMenu';

const Header = ({ data, selected }) => {
  const [expandedNav, setExpandedNav] = useState(false);

  const styles = css`
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0px 2px 12px 0px rgba(10, 10, 10, 0.15);

    .container {
      display: flex;
    }

    .logo {
      display: flex;
      align-items: center;

      img {
        padding: 14px 0 18px 20px;
      }
    }

    .navigation {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        font-size: 13px;
        padding: 30px 25px;
        transition: background 0.2s ease-in-out;

        &:hover {
          font-weight: bold;
          background-color: rgba(20, 20, 20, 0.05);
        }
      }
    }

    .navigation-button {
      display: none;
      width: 40px;
      height: 40px;

      svg {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      .navigation-button {
        display: block;

        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 20;
        cursor: pointer;

        .top,
        .mid,
        .bot {
          transform-origin: center center;
          transition: transform 0.3s ease-in-out;
        }
      }

      .logo {
        background-color: #fff;
        position: relative;
        z-index: 10;
      }

      .row {
        display: block;
      }

      .navigation {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;

        box-shadow: 0px 2px 12px 0px rgba(10, 10, 10, 0.15);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        flex-direction: column;
        align-items: stretch;
        text-align: center;
        pointer-events: none;

        a {
          transform: translateY(20px);
          opacity: 0;

          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
      }
    }
  `;

  const expandedNavigationStyle = css`
    background-color: purple;

    @media (max-width: 768px) {
      .navigation {
        opacity: 1;
        pointer-events: auto;

        a {
          transform: translateY(0px);
          opacity: 1;
        }
      }

      .navigation-button {
        .top {
          transform: rotate(45deg) translate(0px, 12px);
        }

        .bot {
          transform: rotate(-45deg) translate(2px, -12px);
        }

        .mid {
          opacity: 0;
          transform: translateX(30px);
        }
      }
    }
  `;

  const selectedStyling = css`
    border-bottom: 3px solid var(--red);
    color: var(--red);
    font-weight: bold;
  `;

  const getNavigationItems = () => {
    const items = data.map((page, index) => {
      return (
        <a
          key={`header-link-${index}`}
          style={{ order: data.length - index }}
          href={`/${page.pageSlug}`}
          className={cx('navigation-item', {
            [selectedStyling]: selected === page.name,
          })}
        >
          {page.name}
        </a>
      );
    });

    return items;
  };

  return (
    <div
      className={cx(styles, 'container', {
        [expandedNavigationStyle]: expandedNav === true,
      })}
    >
      <div className="row">
        <div className="logo">
          <a href="/">
            <img src={Logo} alt="" />
          </a>
        </div>
        <div
          className="navigation-button"
          onClick={() => {
            console.log('hiiii', expandedNav);
            setExpandedNav(!expandedNav);
          }}
        >
          <NavMenu />
        </div>
        <div className="navigation">{getNavigationItems()}</div>
      </div>
    </div>
  );
};

export default Header;
